// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent from '@/Mixins/MixinComponent'

// Component Extend
const StatusBar = VueMixins(MixinComponent).extend({
	name: 'StatusBar',

	data: function() {
		return {
			states: {
				elapsedTime: undefined as string,
				message: '',
				showElapsedTime: false,
				showSpinner: false,
				variant: undefined as string
			}
		}
	},

	methods: {
		setElapsedTime: function(showElapsedTime: boolean, startTime?: number, endTime?: number) {
			const elapsedTime = startTime !== undefined && endTime !== undefined ? ((endTime - startTime) / 1000).toFixed(2) : ''
			this.setStates<StatusBarRef['states']>({ elapsedTime, showElapsedTime })
		},

		setStatus: function(showSpinner: boolean, message: string, variant = undefined as string) {
			this.setStates<StatusBarRef['states']>({ message, showSpinner, variant })
		}
	}
})

// Exports
export default StatusBar
export type StatusBarRef = InstanceType<typeof StatusBar>