// Constants
import { VueRouter } from '@/Constants/VueRouter'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const AdminNavigation = VueMixins(MixinResponsive).extend({
	name: 'AdminNavigation',

	props: {
		selected: Number
	},

	data: function() {
		return {
			items: [
				{ name: VueRouter.Views.Modules.ADMIN_EXTERNAL_USERS.NAME, label: 'Usuarios Clientes' },
				{ name: VueRouter.Views.Modules.ADMIN_INTERNAL_USERS.NAME, label: 'Usuarios Internos' },
				{ name: VueRouter.Views.Modules.ADMIN_STORAGES.NAME, label: 'Gestión Plantas' },
				{ name: VueRouter.Views.Modules.ADMIN_SETTINGS.NAME, label: 'Configuraciones'}
			]
		}
	},

	methods: {
		onItemClick: function({ name }: any) {
			this.$router.push({ name })
		}
	}
})

// Exports
export default AdminNavigation
export type AdminNavigationRef = InstanceType<typeof AdminNavigation>