// src
import Config from '@/config'

// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { RecordHelper }      from '@/Classes/Records/RecordHelper'

// Constants
import { ActionFields } from '@/Constants/ActionFields'
import { AppValues }    from '@/Constants/AppValues'

// Helpers
import { BuilderManager } from '@/Helpers/Components/DataTable/BuilderManager'

// Namespace Export
export namespace DataParsers {
	export function parseEquipments(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)
	
		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idStorage', '_idEquipment', 'groupEquipment', 'hourmeter', 'storageCode', 'typeArticle')
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('code', 'Código').isSortable().setAlign(null, 'center')
			.add('codeArticle', 'Cód. Artículo').isSortable().setAlign(null, 'center')
			.add('name', 'Descripción').isSortable()
			.add('typeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center')
			.add('mark', 'Marca').isSortable().setAlign(null, 'center')
			.add('year', 'Año').isSortable().setAlign(null, 'center')
			.add('companyName', 'Cliente').isSortable().setAlign(null, 'center').setSearchKey('company.name')
			.add('storageName', 'Planta').isSortable().setAlign(null, 'center').setSearchKey('storage.name')
			.add('groupArticle', 'Grupo Artículo').isSortable().setAlign(null, 'center')
			.add('isValid', '¿Esta Activo?').isSortable().setAlign(null, 'center')
		)
	
		// Aplicar un reordenamiento a los campos.
		bm.rearrangeFields('code', 'codeArticle', 'name', 'typeEquipment', 'mark', 'year', 'companyName', 'storageName', 'groupArticle', 'isValid')
	
		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(new ItemMissingFields()
			.add('companyName')
			.add('storageName')
		)
	
		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters({
			mark: (v: string) => {
				return v || AppValues.Strings.DEFAULT_EMPTY_STRING
			},
			groupEquipment: (v: string) => {
				return v || AppValues.Strings.DEFAULT_EMPTY_STRING
			},
			typeArticle: (v: string) => {
				return v || AppValues.Strings.DEFAULT_EMPTY_STRING
			},
			typeEquipment: (v: string) => {
				return v || AppValues.Strings.DEFAULT_EMPTY_STRING
			},
			isValid: (v: boolean) => {
				return v ? 'Sí' : 'No'
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.packWithActions()
	}

	export function parseEquipmentsStacked(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)
	
		// Mostrar unicamemte los campos que queremos en la tabla.
		bm.showFields('code', 'name')
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('code', 'Código').isSortable().showDetailsButton()
			.add('name', 'Descripción').isSortable()
		)
	
		// Aplicar un reordenamiento a los campos.
		bm.rearrangeFields('code', 'name')
	
		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(new ItemMissingFields()
			.add('storageName')
		)
	
		// Definir los campos a mostrar en el Detalle.
		bm.parseItemsDetail({
			[RecordHelper.setFlags('Tipo Equipo', 'typeEquipment')]: ({ typeEquipment }) => {
				return typeEquipment || AppValues.Strings.DEFAULT_EMPTY_STRING
			},
			[RecordHelper.setFlags('Marca', 'mark')]: ({ mark }) => {
				return mark || AppValues.Strings.DEFAULT_EMPTY_STRING
			},
			[RecordHelper.setFlags('Año', 'year')]: ({ year }) => {
				return year || AppValues.Strings.DEFAULT_EMPTY_STRING
			},
			[RecordHelper.setFlags('Cliente', 'companyName', 'company.name')]: ({ companyName }) => {
				return companyName || AppValues.Strings.DEFAULT_EMPTY_STRING
			},
			[RecordHelper.setFlags('Planta', 'storageName', 'storage.name')]: ({ storageName }) => {
				return storageName || AppValues.Strings.DEFAULT_EMPTY_STRING
			},
			[RecordHelper.setFlags('Grupo Artículo', 'groupArticle')]: ({ groupArticle }) => {
				return groupArticle
			},
			[RecordHelper.setFlags('¿Esta Activa?', 'isValid')]: ({ isValid }) => {
				return isValid ? 'Sí' : 'No'
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.packWithActions()
	}

	export function parseRoles(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data, ActionFields.Edit)
	
		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idRole', 'permissions')
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('name', 'Nombre').isSortable()
		)
	
		if (Config.entries.removeRootRole) {
			// Eliminar los items que no queremos mostrar en la tabla.
			bm.removeItems(
				{ field: 'name', value: 'Root' }
			)
		}
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.packWithActions()
	}
	
	export function parseRolesStacked(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)
	
		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idRole', 'permissions')
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('name', 'Nombre').isSortable().showDetailsButton()
		)
	
		if (Config.entries.removeRootRole) {
			// Eliminar los items que no queremos mostrar en la tabla.
			bm.removeItems(
				{ field: 'name', value: 'Root' }
			)
		}
	
		// Definir los campos a mostrar en el Detalle.
		bm.parseItemsDetail({
			['Acciones']: ActionFields.Edit
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.packWithActions()
	}
	
	export function parseWorkAreas(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data, ActionFields.Edit)
	
		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idWorkArea', '_idLeader')
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('name', 'Nombre').isSortable()
			.add('leadername', 'Líder').isSortable().setAlign(null, 'center').setSearchKey('leader.name')
			.add('isValid', '¿Esta Activa?').isSortable().setAlign(null, 'center').setStyleTh({ width: '100px' })
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields('name', 'leadername', 'isValid')
	
		if (Config.entries.removeRootWorkArea) {
			// Eliminar los items que no queremos mostrar en la tabla.
			bm.removeItems(
				{ field: 'name', value: 'Root' }
			)
		}
	
		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(new ItemMissingFields()
			.add('leadername')
		)
	
		// Definir los formatos que serán aplicados al valor de la celda.
		bm.parseItemsFormatters({
			isValid: (v: boolean) => {
				return v ? 'Sí' : 'No'
			}
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.packWithActions()
	}
	
	export function parseWorkAreasStacked(data: Array<any>) {
		// Clase Constructora.
		const bm = new BuilderManager(data)
	
		// Eliminar los campos que no queremos mostrar en la tabla.
		bm.removeFields('_idWorkArea', '_idLeader', 'isValid')
	
		// Aplicar opciones a los campos.
		bm.parseFieldsOptions(new FieldsOptions()
			.add('name', 'Nombre').isSortable().showDetailsButton()
			.add('leadername', 'Líder').isSortable()
		)
	
		// Aplicar un nuevo Orden a todas las columnas visibles.
		bm.rearrangeFields('name', 'leadername')
	
		if (Config.entries.removeRootWorkArea) {
			// Eliminar los items que no queremos mostrar en la tabla.
			bm.removeItems(
				{ field: 'name', value: 'Root' }
			)
		}
	
		// Aplicar un valor a los campos e items si no estan presentes.
		bm.parseItemsMissingFields(new ItemMissingFields()
			.add('leadername')
		)
	
		// Definir los campos a mostrar en el Detalle.
		bm.parseItemsDetail({
			[RecordHelper.setFlags('¿Esta Activo?', 'isValid')]: ({ isValid }) => {
				return isValid ? 'Sí' : 'No'
			},
			['Acciones']: ActionFields.Edit
		})
	
		// Retornar Objeto con los Campos, Items y Acciones.
		return bm.packWithActions()
	}
}